import { ACTION_TRAFFIC_SOURCE } from './actions'
import { ITrafficSource, ITrafficSourceRequest } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import trafficSourceApi from '@/api/trafficSource'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: ITrafficSource[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    trafficSources: (state: TState) => {
      return state.items as ITrafficSource[]
    },
  },
  mutations: {
    // [List]
    [ACTION_TRAFFIC_SOURCE.SET_ITEMS]: (state: TState, { items }: { items: ITrafficSource[] }) => {
      state.items = cloneDeep(items) as ITrafficSource[]
    },

    // [New item]
    [ACTION_TRAFFIC_SOURCE.SET_NEW_ITEM]: (state: TState, { item }: { item: ITrafficSource }) => {
      state.items = apiListChangedHandler<ITrafficSource>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_TRAFFIC_SOURCE.SET_UPDATED_ITEM]: (state: TState, { item }: { item: ITrafficSource }) => {
      state.items = apiListChangedHandler<ITrafficSource>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_TRAFFIC_SOURCE.SET_DELETED_ITEM]: (state: TState, { item }: { item: ITrafficSource }) => {
      state.items = apiListChangedHandler<ITrafficSource>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_TRAFFIC_SOURCE.LOAD_ITEMS]: async ({ commit }, params: ITrafficSourceRequest) => {
      const key = constant.LOCAL_KEYS.TRAFFIC_SOURCES + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await trafficSourceApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_TRAFFIC_SOURCE.SET_ITEMS, { items: resp })
      return resp
    },

    // eslint-disable-next-line
    [ACTION_TRAFFIC_SOURCE.LOAD_ITEM]: async ({ commit }, id: string) => {
      const resp = await trafficSourceApi.single(id)
      return resp
    },
    [ACTION_TRAFFIC_SOURCE.ADD]: async ({ commit }, payload: ITrafficSource) => {
      const resp = await trafficSourceApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TRAFFIC_SOURCES + resp.app_id)
        commit(ACTION_TRAFFIC_SOURCE.SET_NEW_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_TRAFFIC_SOURCE.UPDATE]: async ({ commit }, payload: ITrafficSource) => {
      const resp = await trafficSourceApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TRAFFIC_SOURCES + resp.app_id)
        commit(ACTION_TRAFFIC_SOURCE.SET_UPDATED_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_TRAFFIC_SOURCE.DELETE]: async ({ commit }, id: string) => {
      const resp = await trafficSourceApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TRAFFIC_SOURCES + resp.app_id)
        commit(ACTION_TRAFFIC_SOURCE.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_TRAFFIC_SOURCE.IS_ACTIVE]: async ({ commit }, payload: ITrafficSource) => {
      const resp = await trafficSourceApi.active(payload)
      if (resp?._id) {
        commit(ACTION_TRAFFIC_SOURCE.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },

    [ACTION_TRAFFIC_SOURCE.TEST_MODE]: async ({ commit }, payload: ITrafficSource) => {
      const resp = await trafficSourceApi.testMode(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TRAFFIC_SOURCES + payload.app_id)
        commit(ACTION_TRAFFIC_SOURCE.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
  },
}
