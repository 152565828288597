<template>
  <q-item clickable active-class="active-menu-link" v-ripple @click="gotoHome()" class="app-logo absolute-top">
    <q-item-section avatar class="q-pr-none">
      <q-avatar square v-if="miniState">
        <img src="/img/line-logo.jpg" />
      </q-avatar>
      <img v-else src="/img/line-logo.jpg" width="40" />
    </q-item-section>
    <q-item-section> <img src="/img/poti-poti.jpg" width="134" class="center" /> </q-item-section>
  </q-item>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class AppLogo extends Vue {
  @Prop()
  miniState!: boolean

  gotoHome() {
    this.$router.push({ name: 'home' })
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';

.app-logo {
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
