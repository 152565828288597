
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { ACTION_APP, ACTION_PROFILE } from '@/store/actions'
import { UserModel } from '@/models/user-model'

import UserAvatar from '@/components/user/UserAvatar.vue'
import { IApp } from '@/utils/types'

@Options({
  components: {
    UserAvatar,
  },
  emits: ['drawerClick:collapsed'],
})
export default class AppSidebarTop extends Vue {
  @Prop({ default: false })
  miniState!: boolean

  selectingApp: IApp = {}

  get displayLeftSidebar() {
    return this.$route.meta?.hasLeftSidebar
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get activeRouteKey() {
    return this.$route.params?.id || this.$route.meta?.menuKey
  }

  get appId() {
    return this.$route.params.app_id
  }

  logout() {
    try {
      this.$store.dispatch(ACTION_PROFILE.LOGOUT)
      this.goto('login')
    } catch (error) {
      console.log(error)
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  async fetchSelectingApp() {
    if (!this.appId) {
      this.selectingApp = {}
      return
    }

    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.appId)
  }

  @Emit('drawerClick:collapsed')
  drawerClick() {
    return !this.miniState
  }

  @Watch('appId', { immediate: true })
  appIdChanged() {
    // [AppMixin]
    this.fetchSelectingApp()
  }

  // async created() {
  //   await this.$store.dispatch(ACTION_APP.LOAD_ITEMS)
  // }
}
