
import { Watch } from 'vue-property-decorator'
import { Vue, Options } from 'vue-class-component'
import { ACTION_PROFILE } from '@/store/actions'
import { localStore } from '@/utils/localstore'
import debounce from 'quasar/src/utils/debounce.js';
import { UserModel } from '@/models/user-model'

import UserAvatar from '@/components/user/UserAvatar.vue'
import AppSidebarTop from '@/components/layout/AppSidebarTop.vue'
import AppLogo from '../common/AppLogo.vue'

@Options({
  components: { UserAvatar, AppSidebarTop, AppLogo },
  emits: ['update:collapsed'],
})
export default class AppSidebarLeft extends Vue {
  selected = ''
  miniState = false
  drawer = false

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get activeRouteKey() {
    return this.$route.meta?.menuKey
  }

  get displayLeftSidebar() {
    return this.$route.meta?.hasLeftSidebar
  }

  get appId() {
    return this.$route.params.app_id
  }

  get activePage() {
    return this.$route.name || 'overview'
  }

  @Watch('miniState')
  onCollapseChanged() {
    // Remove scroll left on mini state. Quasar has a strange thing here
    debounce(() => {
      if (this.$refs.leftSidebarRef) {
        this.$refs.leftSidebarRef.$el.classList.remove('q-drawer--mini-animate')
      }
    }, 150).call(this)
  }

  drawerClick() {
    this.miniState = !this.miniState
  }

  gotoDetail(page) {
    this.goto(page, { app_id: this.appId, page })
  }

  gotoUserProfile() {
    this.goto('user_profile_view', { id: this.userInfo._id })
  }

  gotoEditProfile() {
    this.goto('user_profile_edit')
  }

  gotoHome() {
    this.goto('home')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  logout() {
    try {
      this.$store.dispatch(ACTION_PROFILE.LOGOUT)
      this.goto('login')
    } catch (error) {
      console.log(error)
    }
  }

  created() {
    this.miniState = !!localStore.getItem(this.collapseKey)
  }
}
