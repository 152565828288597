<template>
  <q-header class="bg-secondary-custom topbar">
    <q-toolbar>
      <q-btn color="dark" class="q-ml-sm" flat @click="drawerClick" round dense icon="menu" />
      <q-toolbar-title>{{ selectingApp.bot_name }}</q-toolbar-title>
      <q-btn round icon="settings" size="sm" class="q-mr-sm">
        <q-menu anchor="bottom right" self="top right">
          <q-list dense style="min-width: 200px" class="q-py-sm">
            <q-item clickable v-if="userInfo?.isSuper" @click="goto('users')">
              <q-item-section avatar>
                <q-icon name="people" />
              </q-item-section>
              <q-item-section> {{ $t('menu.manage_users') }} </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-btn round size="sm">
        <UserAvatar :user="userInfo" />
        <q-menu anchor="bottom right" self="top right">
          <q-list dense style="min-width: 200px" class="q-py-sm">
            <q-item>
              <q-item-section avatar>
                <UserAvatar :size="24" :user="userInfo" />
              </q-item-section>
              <q-item-section class="text-bold">{{ userInfo?.display_name }}</q-item-section>
            </q-item>
            <q-item clickable @click="goto('settings')">
              <q-item-section avatar>
                <q-icon name="settings" />
              </q-item-section>
              <q-item-section> {{ $t('menu.my_page') }} </q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable v-close-popup @click="logout">
              <q-item-section avatar>
                <q-icon name="logout" />
              </q-item-section>
              <q-item-section> {{ $t('logout') }} </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-toolbar>
  </q-header>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { ACTION_APP, ACTION_PROFILE } from '@/store/actions'
import { UserModel } from '@/models/user-model'

import UserAvatar from '@/components/user/UserAvatar.vue'
import { IApp } from '@/utils/types'

@Options({
  components: {
    UserAvatar,
  },
  emits: ['drawerClick:collapsed'],
})
export default class AppSidebarTop extends Vue {
  @Prop({ default: false })
  miniState!: boolean

  selectingApp: IApp = {}

  get displayLeftSidebar() {
    return this.$route.meta?.hasLeftSidebar
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get activeRouteKey() {
    return this.$route.params?.id || this.$route.meta?.menuKey
  }

  get appId() {
    return this.$route.params.app_id
  }

  logout() {
    try {
      this.$store.dispatch(ACTION_PROFILE.LOGOUT)
      this.goto('login')
    } catch (error) {
      console.log(error)
    }
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  async fetchSelectingApp() {
    if (!this.appId) {
      this.selectingApp = {}
      return
    }

    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.appId)
  }

  @Emit('drawerClick:collapsed')
  drawerClick() {
    return !this.miniState
  }

  @Watch('appId', { immediate: true })
  appIdChanged() {
    // [AppMixin]
    this.fetchSelectingApp()
  }

  // async created() {
  //   await this.$store.dispatch(ACTION_APP.LOAD_ITEMS)
  // }
}
</script>
<style lang="scss" scoped>
.topbar {
  border-bottom: 1px solid #d0d2e0;
  padding: 5px 0;
  z-index: 1;
  &:after {
    content: '';
    z-index: 0;
    position: absolute;
    left: 2.5%;
    bottom: -1px;
    width: 95%;
    height: 4px;
    border-radius: 100%;
    box-shadow: 0 3px 6px rgb(0 0 0 / 12%);
  }
}
.bg-secondary-custom {
  background: #7491bd !important;
  color: #fff !important;
}
</style>
