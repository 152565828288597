import axios from 'axios'
import store from '@/store'
import { ACTION_PROFILE } from '@/store/actions'
import { localStore } from '@/utils/localstore'
import { APP_USER_STORE_KEY } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import router from '@/router'
export const baseUrl = `${process.env.VUE_APP_API_ENDPOINT}`

interface IRequestHeader {
  [key: string]: string
}

const headers: IRequestHeader = {
  'Access-Control-Allow-Origin': '*',
}

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 55000,
  withCredentials: false,
  headers,
})

axiosInstance.interceptors.request.use((config) => {
  const token = localStore.getItem(APP_USER_STORE_KEY.AUTH_TOKEN)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

const getErrorMessage = (error) => {
  if (error.response?.data?.details) {
    const detailsString: string = error.response?.data?.details.map(
      detail => `- <span style="text-transform: capitalize">${detail.property}</span> ${detail.message}`
    ).join('<br>')
    if (detailsString) {
      return error.response?.data?.message + ': <br>' + detailsString
    }
  }
  return error.response?.data?.message || error.response?.statusText
}

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    switch (error.response?.status) {
      case 400:
      case 404:
        errorHandler(getErrorMessage(error))
        break
      case 401:
        // errorHandler(getErrorMessage(error))
        store.commit(ACTION_PROFILE.CLEAR_PROFILE)
        // Token has expired
        // https://camach-line-api.sango-testing.in/v1/profile
        if (error?.request?.responseURL?.includes('v1/profile')) {
          router.push('/login')
        }

        break

      case 409:
        errorHandler(getErrorMessage(error))
        break

      case 403:
        throw new Error('409')

      case 500:
        throw new Error('500')

      default:
        return Promise.reject(error)
    }
  }
)

export class NormApi<T> {
  axios = axiosInstance

  // eslint-disable-next-line
  responseHandler(response: any) {
    return response?.data?.data || response?.data || response
  }
}
