import { SET_LOADING, CLEAR_LOADING, ACTION_PROFILE } from './actions'
import { ILoginPayload, IRegisterPayload, IResetPasswordPayload, IUser } from '@/utils/types'
import { localStore } from '@/utils/localstore'
import { APP_USER_STORE_KEY } from '@/utils/constants'
import { UserModel } from '@/models/user-model'
import UserApi from '@/api/user'

type TProfileState = {
  user: UserModel | null
  alreadyGetProfileOnInit: boolean
  loading: boolean
}

export default {
  state: {
    user: null,
    alreadyGetProfileOnInit: false,
    loading: false,
  },
  getters: {
    isAuth: (state: TProfileState) => {
      return !!state.user
    },
    isLoading: (state: TProfileState) => {
      return state.loading
    },
    userInfo: (state: TProfileState) => {
      return state.user
    },
    alreadyGetProfileOnInit: (state: TProfileState) => {
      return state.alreadyGetProfileOnInit
    },
  },
  mutations: {
    [ACTION_PROFILE.SAVE]: (
      state: TProfileState,
      {
        user,
      }: {
        user: IUser
      }
    ) => {
      state.user = user ? new UserModel(user) : user
      state.loading = false
      state.alreadyGetProfileOnInit = true
    },
    [ACTION_PROFILE.CLEAR_PROFILE]: (state: TProfileState) => {
      state.user = null
      state.loading = false
    },
    [SET_LOADING]: (state: TProfileState) => {
      state.loading = true
    },
    [CLEAR_LOADING]: (state: TProfileState) => {
      state.loading = false
    },
  },
  actions: {
    [ACTION_PROFILE.LOGOUT]: async ({ commit }: { commit: Function }) => {
      commit(ACTION_PROFILE.CLEAR_PROFILE)
      localStore.removeItem(APP_USER_STORE_KEY.AUTH_TOKEN)
    },
    [ACTION_PROFILE.LOGIN]: async ({ dispatch }, payload: ILoginPayload) => {
      const resp = await UserApi.login(payload)
      localStore.removeLocalData()
      if (resp?.access_token) {
        localStore.setItem(APP_USER_STORE_KEY.AUTH_TOKEN, resp?.access_token)
        dispatch(ACTION_PROFILE.LOAD)
      }
    },
    [ACTION_PROFILE.REGISTER]: async ({ dispatch }, payload: IRegisterPayload) => {
      const resp = await UserApi.register(payload)

      if (resp?.access_token) {
        localStore.setItem(APP_USER_STORE_KEY.AUTH_TOKEN, resp?.access_token)
        dispatch(ACTION_PROFILE.LOAD)
      }
    },
    // eslint-disable-next-line
    [ACTION_PROFILE.UPDATE]: async ({ commit }, payload: IRegisterPayload) => {
      const resp = await UserApi.updateProfile(payload)
      return resp
    },

    [ACTION_PROFILE.LOAD]: async ({ commit }) => {
      const resp = await UserApi.getProfile()
      if (resp?._id) {
        commit(ACTION_PROFILE.SAVE, { user: resp })
      }
    },
    // eslint-disable-next-line
    [ACTION_PROFILE.RESET_PASSWORD]: async ({ commit }, payload: IResetPasswordPayload) => {
      const resp = await UserApi.resetPassword(payload)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_PROFILE.FORGOT_PASSWORD]: async ({ commit }, payload: IResetPasswordPayload) => {
      const resp = await UserApi.forgotPassword(payload)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_PROFILE.CHECK_PASSWORD_TOKEN]: async ({ commit }, payload: IResetPasswordPayload) => {
      const resp = await UserApi.checkResetToken(payload)
      return resp
    },
  },
}
