const prefix = 'camach-line-'
export const localStore = {
  makeKey: (key: string) => {
    return `${prefix}${key}`
  },
  getItem: (key: string) => {
    return localStorage.getItem(localStore.makeKey(key))
  },
  setItem: (key: string, value: string) => {
    return localStorage.setItem(localStore.makeKey(key), value)
  },
  remove: (key: string) => {
    return localStorage.removeItem(localStore.makeKey(key))
  },
  removeItem: (key: string) => {
    return localStorage.removeItem(localStore.makeKey(key))
  },
  getUser: () => {
    try {
      const userRaw = localStorage.getItem(localStore.makeKey('user')) as string
      return JSON.parse(userRaw)
    } catch {
      return null
    }
  },
  setWithExpiry(key, value, ttl) {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    return localStorage.setItem(localStore.makeKey(key), JSON.stringify(item))
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getWithExpiry(key: string) {
    // console.log(key)
    // const value: unknown = null // Or whatever value you want to return
    // return value as any
    const itemStr = localStorage.getItem(localStore.makeKey(key))
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(localStore.makeKey(key))
      return null
    }
    return item.value
  },
  removeLocalData() {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(localStore.makeKey('')) && key !== 'camach-line-lang') {
        localStorage.removeItem(key)
      }
    })
  },

  removeStartsWith(prefix) {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(localStore.makeKey(prefix))) {
        localStorage.removeItem(key)
      }
    })
  },
}
