export const constant = {
  DATETIME_FORMAT: 'YYYY-MM-DD HH:mm',
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_FORMAT_YYYYMM: 'YYYY/MM',
  DATE_FORMAT_MMDD: 'MM/DD',
  PREFIX_ANSWER: 'answer_',
  ANSWER_TYPE: {
    TEXT: 'text',
    MESSAGE: 'message',
    IMAGE: 'image',
    IMAGEMAP: 'imagemap',
    IMAGECAROUSEL: 'imagecarousel',
    FREETEXT: 'freetext',
  },
  ROW_PER_PAGE_OPTIONS: [50, 100],
  ROW_PER_PAGE: 50,
  MAX_ITEM_MESSAGE: 5,
  MAX_IMAGE_PER_MESSAGE: 10,
  DEFAULT_ALL_USER_AUDIENCE_ID: 'all_user_audience_id',
  RICH_MENU_IMAGE: {
    IMAGE_DEFAULT_SIZE: 700,
    MIN_IMAGE_WIDTH: 800,
    MAX_IMAGE_WIDTH: 2500,
    MIN_IMAGE_HEIGHT: 250,
    MAX_IMAGE_HEIGHT: 1686,
    MAX_FILE_SIZE: 1048576,
    MIN_ASPECT_RATIO: 1.45,
    TAB_HEIGHT: 281,
  },

  ACTION_TYPE: {
    ADD: 'add',
    EDIT: 'edit',
    COPY: 'copy',
  },

  animation_type: {
    fade_in: 1,
    rotate: 2,
    cut_from_bottom: 3,
    cut_from_top: 4,
    cut_from_left: 5,
    cut_from_right: 6,
  },

  DATE_RANGE: 92,

  LOCAL_KEY_PREFIX: 'camach-line-',
  LOCAL_KEYS: {
    APPS: 'apps',
    ACTIVITY_LOGS: 'activity-logs-',
    APP_USERS: 'app-users-',
    ANSWER_REPORT: 'answer-report-',
    APP_OVER_VIEW: 'app-over-view-',
    APP_OVER_VIEW_DETAIL: 'app-over-view-detail-',
    INITIALS: 'initials-',
    PUSHES: 'pushes-',
    SCENARIO_TEMPLATES: 'scenarios_templates-',
    ASSETS_MESSAGES: 'assets-messages-',
    ASSETS_QAS: 'assets-qas-',
    ASSETS_GOALS: 'assets-goals-',
    ASSETS_FORMS: 'assets-forms-',
    AUDIENCES: 'audiences-',
    AUDIENCE_USERS: 'audience-users-',
    AUDIENCE_USER_DETAILS: 'audience-user-details-',
    CAMPAIGNS: 'campaigns-',
    CAMPAIGNS_COMPACT: 'campaigns-compact-',
    CAMPAIGN_TAGURL: 'campaign-tagurl',
    DELIVER_LOGS: 'deliver-logs-',
    MEDIAS: 'medias-',
    POPUPS: 'popups-',
    RICH_MENUS: 'rich-menus-',
    TARGET_SETTINGS: 'target-settings-',
    TRAFFIC_SOURCES: 'traffic-sources-',
    USERS: 'users',
    // USER_ANSWER: 'user-answer-',
    USER_ANSWER_ROWS: 'user-answer-rows-',
    VERSION_HISTORY: 'version-history-',
    PERMISSION_USERS: 'permission-users-',
    SESSION_SCENARIOS: 'session-scenarios-',
    AUTO_RESPONSES: 'auto-responses-',
    STORIES: 'stories-',
    STORIES_REPORT: 'stories-report-',
    TOTAL_USERS_REPORT_SCENARIO: 'total-report-scenario-',
  },
  LOCAL_EXPIRED: 3600000,
}

export const IMAGEMAP_MESSAGE = {
  IMAGEMAP_MAX_SIZE: 1040,
  IMAGE_DEFAULT_SIZE: 700,
  IMAGE_SIZES: [1040, 1024, 700, 460, 300, 240],
  ACTION_TYPE: {
    URI: 'uri',
    MESSAGE: 'message',
    RESERVE: 'reservation',
    INITIAL: 'initial',
    PUSH: 'push',
    SWITCH_MENU: 'richmenuswitch',
    PHONE_CALL: 'phone_call',
    OPEN_MAP: 'open_map',
    CALL_SHOP_CARD: 'call_shop_card',
    CALL_COUPON: 'call_coupon',
    SHARE_CHANNEL: 'share_channel',
    CALL_ACCOUNT_PROFILE: 'call_account_profile',
    OPEN_LINE_VOOM: 'open_line_voom',
  },
  DEFAULT_SIZE: {
    WIDTH: 50,
    HEIGHT: 50,
  },
  PREFIX_TARGET: 'target_',
  PREFIX_AREA: 'area_',
  MAX_FILE_SIZE: 10485760,
}

export const APP_USER_STORE_KEY = {
  AUTH_TOKEN: 'auth-token',
}

export const PLATFORM = {
  LINE: 'line',
  ZALO: 'zalo',
}

export const SCENARIO_MAKER = {
  FROM_IFRAME: {
    READY: 'IFRAME_READY',
    IFRAME_NEW_DATA: 'IFRAME_NEW_DATA',
    IS_EDITING: 'IFRAME_IS_EDITING',
    DATE_RANGE: 'IFRAME_DATE_RANGE',
    ON_CARD_EDIT: 'ON_CARD_EDIT',
    ON_OPEN_PREVIEW: 'ON_OPEN_PREVIEW',
    ON_CHANGE_DIRECTION: 'ON_CHANGE_DIRECTION',
    OPEN_SELECT_NEXT_QUESTION: 'OPEN_SELECT_NEXT_QUESTION',
    OPEN_SELECT_NEXT_MESSAGE: 'OPEN_SELECT_NEXT_MESSAGE',
    OPEN_SELECT_NEXT_GOAL: 'OPEN_SELECT_NEXT_GOAL',
    OPEN_SELECT_NEXT_FORM: 'OPEN_SELECT_NEXT_FORM',
    ON_OPEN_HISTORY: 'ON_OPEN_HISTORY',
  },
  FROM_PARENT: {
    CURRENT_VALUE: 'PARENT_CURRENT_VALUE',
    DIRECTION: 'DIRECTION',
    ADD_CARD: 'ADD_CARD',
    UPDATE_CARD: 'UPDATE_CARD',
    MSG_LAST_HISTORY: 'MSG_LAST_HISTORY',
    ITEM_HISTORY: 'ITEM_HISTORY',
    ERROR_CARD: 'ERROR_CARD',
    CTRL_Z: 'CTRL_Z',
  },
}

export const CAMPAIGN_TYPE = {
  INITIAL: 'initial',
  PUSH: 'push',
  IMPORT: 'import',
}

export const SCHEDULE_TYPE = {
  ONE_TIME: 'one_time',
  MULTI_TIME: 'multi_time',
}

export const SCHEDULE_BY_TYPE = {
  BY_DELAY_DATE: 'by_delay_date',
  BY_WEEK: 'by_week',
  BY_MONTH: 'by_month',
}

export const FREETEXT_OPTION_TYPE = {
  FREETEX: 'freetext',
  PHONE_NUMBER_DASH: 'phone_number',
  PHONE_NUMBER_NO_DASH: 'phone_number_no_dash',
  EMAIL: 'email',
}

export const FORM_FIELD_TYPE = {
  FREETEX: 'freetext',
  NAME_KANJI: 'name_kanji',
  NAME_HIRA: 'name_hira',
  NAME_KANA: 'name_kana',
  PHONE_NUMBER_DASH: 'phone_number',
  PHONE_NUMBER_NO_DASH: 'phone_number_no_dash',
  ADDRESS: 'address',
  POSTCODE_DASH: 'postcode_dash',
  POSTCODE_NO_DASH: 'postcode_no_dash',
  EMAIL: 'email',
}

export const AUTO_RESPONSE = {
  FIX_RESPONSE: 'fix_response',
  KEYWORD_RESPONSE: 'keyword_response',
  DATE_SCHEDULE: 'date',
  TIME_SCHEDULE: 'time',
}

export const STARTING_POINT_TYPE = {
  SCENARIO_START: 'scenario_start',
  SCENARIO_COMPLETED: 'scenario_completed',
  // UNREACHED_USERS: 'unreached_users',
}

export const UNIT_TYPE = {
  ANSWER_UNIT: 'answer_unit',
  MESSAGE_QA_UNIT: 'message_qa_unit',
}

export const DELIVERY_TARGET_TYPE = {
  RESPONDING_USERS: 'responding_users_type',
  UNANSWERED_USERS: 'unanswered_users_type',
  ALL: 'all',
}

export const TRIGGER_TYPE = {
  DEFAULT: 'default',
  QR_CODE: 'qr_code',
  POPUP_DELIVER: 'popup_deliver',
  AUDIENCE: 'audience',
  ALL: 'all',
}
