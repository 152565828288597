import { IPermissionRequest, IPermissionUsers, IUser } from '@/utils/types'
import { ACTION_PERMISSION } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { constant } from '@/utils/constants'
import { localStore } from '@/utils/localstore'
import permissionApi from '@/api/permission'

type TState = {
  users: IUser[]
  loading: boolean
}

export default {
  state: {
    users: [],
    loading: false,
  },
  getters: {
    permission_users: (state: TState) => {
      return state.users as IUser[]
    },
  },
  mutations: {
    // [List users]
    [ACTION_PERMISSION.SET_ITEMS]: (state: TState, { items }: { items: IUser[] }) => {
      state.users = cloneDeep(items)
    },
  },
  actions: {
    [ACTION_PERMISSION.LOAD_ITEMS]: async ({ commit }, params: IPermissionRequest) => {
      const key = constant.LOCAL_KEYS.PERMISSION_USERS + params.app_id
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await permissionApi.list_users(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_PERMISSION.SET_ITEMS, { items: resp })
      return resp
    },
    [ACTION_PERMISSION.SYNC_PERMISSION_USERS]: async ({ commit }, payload: IPermissionUsers) => {
      const resp = await permissionApi.sync_users(payload)

      if (resp) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.PERMISSION_USERS + payload.app_id)
        commit(ACTION_PERMISSION.SET_ITEMS, { items: resp })
        return resp
      }
      return null
    },
  },
}
