import { ACTION_MEDIA } from './actions'
import { IMedia } from '@/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import MediaApi from '@/api/media'
import { apiListChangedHandler } from '@/utils/helpers'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type MediaState = {
  media: IMedia[]
  loading: boolean
}

export default {
  state: {
    media: [],
    loading: false,
  },
  getters: {
    media: (state: MediaState) => {
      return state.media as IMedia[]
    },
  },
  mutations: {
    // [List]
    [ACTION_MEDIA.SET_ITEMS]: (state: MediaState, { media }) => {
      state.media = cloneDeep(media.items)
    },

    // [New item]
    [ACTION_MEDIA.SET_NEW_MEDIA]: (state: MediaState, { item }: { item: IMedia }) => {
      state.media = apiListChangedHandler<IMedia>(state.media, cloneDeep(item), 'added')
    },

    // [Update item]
    [ACTION_MEDIA.SET_UPDATED_MEDIA]: (state: MediaState, { item }: { item: IMedia }) => {
      state.media = apiListChangedHandler<IMedia>(state.media, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_MEDIA.SET_DELETED_MEDIA]: (state: MediaState, { item }: { item: IMedia }) => {
      state.media = apiListChangedHandler<IMedia>(state.media, cloneDeep(item), 'removed')
    },
  },
  actions: {
    [ACTION_MEDIA.LOAD_ITEMS]: async ({ commit }, payload: IMedia) => {
      const key = constant.LOCAL_KEYS.MEDIAS + payload.app_id + JSON.stringify(payload)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await MediaApi.list(payload)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_MEDIA.SET_ITEMS, { media: resp })
      return resp
    },

    [ACTION_MEDIA.ADD]: async ({ commit }, payload: IMedia) => {
      const resp = await MediaApi.add(payload)

      resp.forEach(function(addItem: IMedia) {
        if (addItem?._id) {
          localStore.removeStartsWith(constant.LOCAL_KEYS.MEDIAS + addItem.app_id)
          commit(ACTION_MEDIA.SET_NEW_MEDIA, { item: addItem })
        }
      })
      if (resp.length > 0) {
        return resp
      }

      return null
    },
    [ACTION_MEDIA.UPDATE]: async ({ commit }, payload: IMedia) => {
      const resp = await MediaApi.update(payload)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.MEDIAS + resp.app_id)
        commit(ACTION_MEDIA.SET_UPDATED_MEDIA, { item: resp })
        return resp
      }

      return null
    },
    [ACTION_MEDIA.DELETE]: async ({ commit }, id: string) => {
      const resp = await MediaApi.delete(id)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.MEDIAS + resp.app_id)
        commit(ACTION_MEDIA.SET_DELETED_MEDIA, { item: resp })
        return true
      }
    },
  },
}
