import { IQuestion, IQuestionListRequest } from '@/utils/types'
import { NormApi } from './norm'

export class QuestionApi extends NormApi<IQuestion> {
  list = async (params: IQuestionListRequest) => {
    const response = await this.axios.get('/v1/questions', {
      params,
    })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/questions/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IQuestion) => {
    const response = await this.axios.post('/v1/questions/add', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IQuestion) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/questions/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/questions/${id}`)
    return this.responseHandler(response)
  }
}

const Question = new QuestionApi()
export default Question
