import { IAutoResponse, IAutoResponseRequest } from '@/utils/types'
import { ACTION_AUTO_RESPONSE, ACTION_INITIAL } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { apiListChangedHandler } from '@/utils/helpers'
import autoResponseApi from '@/api/autoResponse'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IAutoResponse[]
  autoResponse: IAutoResponse
  loading: boolean
}

export default {
  state: {
    autoResponse: [],
    loading: false,
  },
  getters: {
    autoResponses: (state: TState) => {
      return state.items as IAutoResponse[]
    },
  },
  mutations: {
    // [List]
    [ACTION_AUTO_RESPONSE.SET_ITEMS]: (state: TState, { items }: { items: IAutoResponse[] }) => {
      state.items = cloneDeep(items)
    },
    // [New item]
    [ACTION_AUTO_RESPONSE.SET_NEW_ITEM]: (state: TState, { item }: { item: IAutoResponse }) => {
      state.items = apiListChangedHandler<IAutoResponse>(state.items, cloneDeep(item), 'added')
    },
    // [Update item]
    [ACTION_AUTO_RESPONSE.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IAutoResponse }) => {
      state.items = apiListChangedHandler<IAutoResponse>(state.items, cloneDeep(item), 'modified')
    },
    // [Deleted item]
    [ACTION_AUTO_RESPONSE.SET_DELETED_ITEM]: (state: TState, { item }: { item: IAutoResponse }) => {
      state.items = apiListChangedHandler<IAutoResponse>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    [ACTION_AUTO_RESPONSE.LOAD_ITEMS]: async ({ commit }, params: IAutoResponseRequest) => {
      const key = constant.LOCAL_KEYS.AUTO_RESPONSES + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await autoResponseApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_AUTO_RESPONSE.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_AUTO_RESPONSE.LOAD_ITEM]: async ({ commit }, id: string) => {
      return await autoResponseApi.single(id)
    },
    [ACTION_AUTO_RESPONSE.ADD_NEW]: async ({ commit }, payload: IAutoResponse) => {
      const resp = await autoResponseApi.add(payload)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUTO_RESPONSES + resp.app_id)
        commit(ACTION_AUTO_RESPONSE.SET_NEW_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_AUTO_RESPONSE.UPDATE]: async ({ commit }, payload: IAutoResponse) => {
      const resp = await autoResponseApi.update(payload)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUTO_RESPONSES + resp.app_id)
        commit(ACTION_AUTO_RESPONSE.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_AUTO_RESPONSE.DELETE]: async ({ commit }, id: string) => {
      const resp = await autoResponseApi.delete(id)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUTO_RESPONSES + resp.app_id)
        commit(ACTION_AUTO_RESPONSE.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_AUTO_RESPONSE.IS_ACTIVE]: async ({ commit }, payload: IAutoResponse) => {
      const resp = await autoResponseApi.active(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUTO_RESPONSES + payload.app_id)
        commit(ACTION_INITIAL.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
  },
}
