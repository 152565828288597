import { ACTION_TARGET_SETTING } from './actions'
import { ITargetSetting, ITargetSettingRequest } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import targetSettingApi from '@/api/targetSetting'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: ITargetSetting[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    targetSettings: (state: TState) => {
      return state.items as ITargetSetting[]
    },
  },
  mutations: {
    // [List]
    [ACTION_TARGET_SETTING.SET_ITEMS]: (state: TState, { items }: { items: ITargetSetting[] }) => {
      state.items = cloneDeep(items) as ITargetSetting[]
    },

    // [New item]
    [ACTION_TARGET_SETTING.SET_NEW_ITEM]: (state: TState, { item }: { item: ITargetSetting }) => {
      state.items = apiListChangedHandler<ITargetSetting>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_TARGET_SETTING.SET_UPDATED_ITEM]: (state: TState, { item }: { item: ITargetSetting }) => {
      state.items = apiListChangedHandler<ITargetSetting>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_TARGET_SETTING.SET_DELETED_ITEM]: (state: TState, { item }: { item: ITargetSetting }) => {
      state.items = apiListChangedHandler<ITargetSetting>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_TARGET_SETTING.LOAD_ITEMS]: async ({ commit }, params: ITargetSettingRequest) => {
      const key = constant.LOCAL_KEYS.TARGET_SETTINGS + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await targetSettingApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_TARGET_SETTING.SET_ITEMS, { items: resp })
      return resp
    },

    // eslint-disable-next-line
    [ACTION_TARGET_SETTING.LOAD_ITEMS_WITHOUT_DETAIL]: async ({ commit }, params: ITargetSettingRequest) => {
      const key = constant.LOCAL_KEYS.TARGET_SETTINGS + params.app_id + '-without-detail-' + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await targetSettingApi.getAllItemsWithoutDetail(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_TARGET_SETTING.SET_ITEMS, { items: resp })
      return resp
    },

    // eslint-disable-next-line
    [ACTION_TARGET_SETTING.LOAD_ITEM]: async ({ commit }, id: string) => {
      const resp = await targetSettingApi.single(id)
      return resp
    },
    [ACTION_TARGET_SETTING.ADD]: async ({ commit }, payload: ITargetSetting) => {
      const resp = await targetSettingApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + resp.app_id)
        commit(ACTION_TARGET_SETTING.SET_NEW_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_TARGET_SETTING.UPDATE]: async ({ commit }, payload: ITargetSetting) => {
      const resp = await targetSettingApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + resp.app_id)
        commit(ACTION_TARGET_SETTING.SET_UPDATED_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_TARGET_SETTING.DELETE]: async ({ commit }, id: string) => {
      const resp = await targetSettingApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + resp.app_id)
        commit(ACTION_TARGET_SETTING.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_TARGET_SETTING.IS_ACTIVE]: async ({ commit }, payload: ITargetSetting) => {
      const resp = await targetSettingApi.active(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + payload.app_id)
        commit(ACTION_TARGET_SETTING.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },

    [ACTION_TARGET_SETTING.TEST_MODE]: async ({ commit }, payload: ITargetSetting) => {
      const resp = await targetSettingApi.testMode(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.TARGET_SETTINGS + payload.app_id)
        commit(ACTION_TARGET_SETTING.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
  },
}
