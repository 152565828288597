import { ACTION_AUDIENCE } from './actions'
import {
  IAudience,
  IAppUser,
  IAudienceAnswer,
  IAudienceAnswerRequest,
  IAudienceUserDetailRequest,
  IForkAudienceRequest,
} from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import audienceApi from '@/api/audience'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IAudience[]
  answers: IAudienceAnswer[]
  users: IAppUser[]
  loading: boolean
}

export default {
  state: {
    items: [],
    answers: [],
    loading: false,
  },
  getters: {
    audiences: (state: TState) => {
      return state.items as IAudience[]
    },

    answers: (state: TState) => {
      return state.answers as IAudienceAnswer[]
    },
  },
  mutations: {
    // [List]
    [ACTION_AUDIENCE.SET_ITEMS]: (state: TState, { items }: { items: IAudience[] }) => {
      state.items = cloneDeep(items)
    },

    // [New item]
    [ACTION_AUDIENCE.SET_NEW_ITEM]: (state: TState, { item }: { item: IAudience }) => {
      state.items = apiListChangedHandler<IAudience>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_AUDIENCE.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IAudience }) => {
      state.items = apiListChangedHandler<IAudience>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_AUDIENCE.SET_DELETED_ITEM]: (state: TState, { item }: { item: IAudience }) => {
      state.items = apiListChangedHandler<IAudience>(state.items, cloneDeep(item), 'removed')
    },

    // [Answer]
    [ACTION_AUDIENCE.SET_AUDIENCE_ANSWER]: (state: TState, { items }: { items: IAudienceAnswerRequest[] }) => {
      state.answers = cloneDeep(items)
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_AUDIENCE.LOAD_ITEMS]: async ({ commit }, params: IAudience) => {
      const resp = await audienceApi.list(params)
      commit(ACTION_AUDIENCE.SET_ITEMS, { items: resp })
      return resp
    },
    [ACTION_AUDIENCE.LOAD_SIMPLE_ITEMS]: async ({ commit }, params: IAudience) => {
      const resp = await audienceApi.simple_list(params)
      commit(ACTION_AUDIENCE.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_AUDIENCE.LOAD_ITEM]: async ({ commit }, id: string) => {
      const resp = await audienceApi.single(id)
      return resp
    },

    [ACTION_AUDIENCE.ADD_NEW]: async ({ commit }, payload: IAudience) => {
      const resp = await audienceApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCES + resp.app_id)
        commit(ACTION_AUDIENCE.SET_NEW_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_AUDIENCE.UPDATE]: async ({ commit }, payload: IAudience) => {
      const resp = await audienceApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCES + resp.app_id)
        commit(ACTION_AUDIENCE.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_AUDIENCE.DELETE]: async ({ commit }, id: string) => {
      const resp = await audienceApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCES + resp.app_id)
        commit(ACTION_AUDIENCE.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },

    [ACTION_AUDIENCE.LOAD_ITEM_ANSWER]: async ({ commit }, params: IAudienceAnswerRequest) => {
      const resp = await audienceApi.answer(params)
      commit(ACTION_AUDIENCE.SET_AUDIENCE_ANSWER, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_AUDIENCE.LOAD_USER_ITEMS]: async ({ commit }, params: IAppUser) => {
      const key = constant.LOCAL_KEYS.AUDIENCE_USERS + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await audienceApi.users(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      return resp
    },
    // eslint-disable-next-line
    [ACTION_AUDIENCE.LOAD_USER_ITEM_DETAILS]: async ({ commit }, params: IAudienceUserDetailRequest) => {
      const key = constant.LOCAL_KEYS.AUDIENCE_USER_DETAILS + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await audienceApi.userDetails(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      return resp
    },
    // eslint-disable-next-line
    [ACTION_AUDIENCE.IS_ACTIVE]: async ({ commit }, payload: IAudience) => {
      const resp = await audienceApi.active(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCES + payload.app_id)
        commit(ACTION_AUDIENCE.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    [ACTION_AUDIENCE.TEST_MODE]: async ({ commit }, payload: IAudience) => {
      const resp = await audienceApi.testMode(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.AUDIENCES + payload.app_id)
        commit(ACTION_AUDIENCE.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    // eslint-disable-next-line
    [ACTION_AUDIENCE.FORK]: async ({ commit }, payload: IForkAudienceRequest) => {
      const resp = await audienceApi.fork(payload)
      if (resp?._id) {
        return true
      }

      return false
    },
  },
}
