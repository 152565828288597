import { IOverViewRequest, IPushCampaign } from '@/utils/types'
import { NormApi } from './norm'

export class IPushApi extends NormApi<IPushCampaign> {
  listSummary = async (payload: IPushCampaign) => {
    const response = await this.axios.post('/v1/push/list-summary', payload)
    return this.responseHandler(response)
  }

  getAllItemsWithoutDetail = async (params: IPushCampaign) => {
    const response = await this.axios.get('/v1/push/list/without-detail', { params })
    return this.responseHandler(response)
  }

  getAllItemsWithDetail = async (params: IPushCampaign) => {
    const response = await this.axios.get('/v1/push/list/with-detail', { params })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/push/${id}`)
    return this.responseHandler(response)
  }

  story_single = async (id: string) => {
    const response = await this.axios.get(`/v1/push/story/${id}`)
    return this.responseHandler(response)
  }

  story_list = async (id: string) => {
    const response = await this.axios.get(`/v1/push/story/${id}/list`)
    return this.responseHandler(response)
  }

  add = async (payload: IPushCampaign) => {
    const response = await this.axios.post('/v1/push', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IPushCampaign) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/push/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/push/${id}`)
    return this.responseHandler(response)
  }

  active = async (payload: IPushCampaign) => {
    const response = await this.axios.post(`/v1/push/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: IPushCampaign) => {
    const response = await this.axios.post(`/v1/push/${payload._id}/mode/test`, payload)
    return this.responseHandler(response)
  }

  runNow = async (id: string) => {
    const response = await this.axios.post(`/v1/push/${id}/test/now`)
    return this.responseHandler(response)
  }

  pushOverviewDetail = async (payload: IOverViewRequest) => {
    const response = await this.axios.post('/v1/push/overview', payload)
    return this.responseHandler(response)
  }

  errorQuota = async (params: IPushCampaign) => {
    const response = await this.axios.get('/v1/push/quota-errors', { params })
    return this.responseHandler(response)
  }

  warningDelay = async (payload: IPushCampaign) => {
    const response = await this.axios.post('/v1/push/warning-delay', payload)
    return this.responseHandler(response)
  }
}

const pushApi = new IPushApi()
export default pushApi
