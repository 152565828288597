import { createStore } from 'vuex'
import profile from './profile'
import app from './app'
import goal from './goal'
import question from './question'
import initial from './initial'
import push from './push'
import message from './message'
import audience from './audience'
import userAnswer from './userAnswer'
import deliverLog from './deliverLog'
import richMenu from './richMenu'
import targetSetting from './targetSetting'
import user from './user'
import versionHistory from './versionHistory'
import campaign from './campaign'
import popup from './popup'
import trafficSource from './trafficSource'
import scenarioTemplate from './scenarioTemplate'
import appUser from './appUser'
import activityLog from './activityLog'
import media from './media'
import form from './form'
import permission from './permission'
import autoResponse from './autoResponse'
import story from './story'

import { SET_PAGE_NOT_FOUND, RESET_PAGE_NOT_FOUND } from './actions'

export interface TAppState {
  isNotFound: boolean
  notFoundItem: string
}

export default createStore({
  strict: true,
  state: {
    isNotFound: false,
    notFoundItem: '',
  },
  mutations: {
    [SET_PAGE_NOT_FOUND]: (state: TAppState, payload?: { item: string }) => {
      state.isNotFound = true
      state.notFoundItem = payload?.item || ''
    },
    [RESET_PAGE_NOT_FOUND]: (state: TAppState) => {
      state.isNotFound = false
      state.notFoundItem = ''
    },
  },
  actions: {
    [SET_PAGE_NOT_FOUND]: (
      {
        commit,
      }: {
        commit: Function
      },
      payload: {
        item: string
      }
    ) => {
      commit(SET_PAGE_NOT_FOUND, payload)
    },
    [RESET_PAGE_NOT_FOUND]: ({ commit }: { commit: Function }) => {
      commit(RESET_PAGE_NOT_FOUND)
    },
  },
  modules: {
    profile,
    app,
    goal,
    question,
    initial,
    push,
    message,
    audience,
    userAnswer,
    deliverLog,
    richMenu,
    targetSetting,
    user,
    versionHistory,
    campaign,
    popup,
    trafficSource,
    scenarioTemplate,
    appUser,
    activityLog,
    media,
    form,
    permission,
    autoResponse,
    story,
  },
})
