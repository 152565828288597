import { IApp, ILoginPayload, IRegisterPayload, IResetPasswordPayload, IUser } from '@/utils/types'
import { NormApi } from './norm'

export class UserApi extends NormApi<IApp> {
  login = async (payload: ILoginPayload) => {
    const response = await this.axios.post('/v1/auth', payload)
    return this.responseHandler(response)
  }

  register = async (payload: IRegisterPayload) => {
    const response = await this.axios.post('/v1/auth/register', payload)
    return this.responseHandler(response)
  }

  getProfile = async () => {
    const response = await this.axios.get('/v1/profile')
    return this.responseHandler(response)
  }

  updateProfile = async (payload: IRegisterPayload) => {
    const response = await this.axios.put('/v1/profile/update', payload)
    return this.responseHandler(response)
  }

  list = async (params: IUser) => {
    const response = await this.axios.get('/v1/users', { params })
    return this.responseHandler(response)
  }

  single = async (params: IUser) => {
    const response = await this.axios.get('/v1/users', { params })
    return this.responseHandler(response)
  }

  add = async (payload: IUser) => {
    const response = await this.axios.post('/v1/users', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IUser) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/users/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/users/${id}`)
    return this.responseHandler(response)
  }

  checkResetToken = async (payload: IResetPasswordPayload) => {
    const response = await this.axios.post('/v1/pwdtoken', payload)
    return this.responseHandler(response)
  }

  resetPassword = async (payload: IResetPasswordPayload) => {
    const response = await this.axios.post('/v1/resetpwd', payload)
    return this.responseHandler(response)
  }

  forgotPassword = async (payload: IResetPasswordPayload) => {
    const response = await this.axios.post('/v1/forgotpwd', payload)
    return this.responseHandler(response)
  }
}

const userApi = new UserApi()
export default userApi
