import { ACTION_USER } from './actions'
import { apiListChangedHandler } from '@/utils/helpers'
import userApi from '@/api/user'
import cloneDeep from 'lodash/cloneDeep'
import { IUser } from '@/utils/types'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IUser[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    users: (state: TState) => {
      return state.items as IUser[]
    },
  },
  mutations: {
    // [List]
    [ACTION_USER.SET_ITEMS]: (state: TState, { items }: { items: IUser[] }) => {
      state.items = cloneDeep(items) as IUser[]
    },

    // [New item]
    [ACTION_USER.SET_NEW_ITEM]: (state: TState, { item }: { item: IUser }) => {
      state.items = apiListChangedHandler<IUser>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_USER.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IUser }) => {
      state.items = apiListChangedHandler<IUser>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_USER.SET_DELETED_ITEM]: (state: TState, { item }: { item: IUser }) => {
      state.items = apiListChangedHandler<IUser>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_USER.LOAD_ITEMS]: async ({ commit }, params: IUser) => {
      const key = constant.LOCAL_KEYS.USERS
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await userApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_USER.SET_ITEMS, { items: resp })
      return resp
    },

    // eslint-disable-next-line
    [ACTION_USER.LOAD_ITEM]: async ({ commit }, params: IUser) => {
      const resp = await userApi.single(params)
      return resp
    },

    [ACTION_USER.ADD_NEW]: async ({ commit }, payload: IUser) => {
      const resp = await userApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.USERS)
        commit(ACTION_USER.SET_NEW_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_USER.UPDATE]: async ({ commit }, payload: IUser) => {
      const resp = await userApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.USERS)
        commit(ACTION_USER.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_USER.DELETE]: async ({ commit }, id: string) => {
      const resp = await userApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.USERS)
        commit(ACTION_USER.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
  },
}
