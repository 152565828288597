import { ITrafficSource, ITrafficSourceRequest } from '@/utils/types'
import { NormApi } from './norm'

export class TrafficSourceApi extends NormApi<ITrafficSource> {
  list = async (params: ITrafficSourceRequest) => {
    const response = await this.axios.get('/v1/traffic-source/list', {
      params,
    })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/traffic-source/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: ITrafficSource) => {
    const response = await this.axios.post('/v1/traffic-source/add', payload)
    return this.responseHandler(response)
  }

  update = async (payload: ITrafficSource) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/traffic-source/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/traffic-source/${id}`)
    return this.responseHandler(response)
  }

  active = async (payload: ITrafficSource) => {
    const response = await this.axios.post(`/v1/traffic-source/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: ITrafficSource) => {
    const response = await this.axios.post(`/v1/traffic-source/${payload._id}/mode/test`, payload)
    return this.responseHandler(response)
  }
}

const trafficSourceApi = new TrafficSourceApi()
export default trafficSourceApi
