import { render } from "./CampaignsFailedMessage.vue?vue&type=template&id=73bd9388"
import script from "./CampaignsFailedMessage.vue?vue&type=script&lang=ts"
export * from "./CampaignsFailedMessage.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBanner,QBtn});
