import { ACTION_ACTIVITY_LOG } from './actions'
import { IActivityLog, ICommonRequest } from '@/utils/types'
import activityLog from '@/api/activityLog'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IActivityLog[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    activityLogs: (state: TState) => {
      return state.items as IActivityLog[]
    },
  },
  mutations: {
    // [List]
    [ACTION_ACTIVITY_LOG.SET_ITEMS]: (state: TState, { items }: { items: IActivityLog[] }) => {
      state.items = cloneDeep(items)
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_ACTIVITY_LOG.LOAD_ITEMS]: async ({ commit }, params: ICommonRequest) => {
      // const key = constant.LOCAL_KEYS.ACTIVITY_LOGS + params.app_id + JSON.stringify(params)
      // let resp
      // resp = localStore.getWithExpiry(key)
      // if (!resp) {
      //   resp = await activityLog.list(params)
      //   localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      // }
      const resp = await activityLog.list(params)
      commit(ACTION_ACTIVITY_LOG.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_ACTIVITY_LOG.CONTENT_ARRAY_BUFFER]: async ({ commit }, params: ICommonRequest) => {
      const resp = await activityLog.contentArraybuffer(params)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_ACTIVITY_LOG.CONTENT_BLOD]: async ({ commit }, params: ICommonRequest) => {
      const resp = await activityLog.contentBlod(params)
      return resp
    },
  },
}
