import { IDeliverLog } from '@/utils/types'
import { NormApi } from './norm'

export class DeliverLogApi extends NormApi<IDeliverLog> {
  list = async (params: IDeliverLog) => {
    const response = await this.axios.post('/v1/deliver-log', params)
    return this.responseHandler(response)
  }
}
const deliverLogApi = new DeliverLogApi()
export default deliverLogApi
