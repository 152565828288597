import BaseModel from '@/models/base-model'

export class UserModel extends BaseModel {
  get canWriteApp() {
    return this.is_admin || this.is_super
  }

  get isSuper() {
    return this.is_super
  }

  _id?: string
  email?: string
  password?: string
  is_active?: boolean
  is_sendmail_password?: boolean
  display_name?: string
  last_logged_in?: Date
  is_admin?: boolean
  is_super?: boolean
  photo_url?: string
}
