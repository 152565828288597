
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { RESET_PAGE_NOT_FOUND, SET_PAGE_NOT_FOUND } from '@/store/actions'
import { UserModel } from './models/user-model'

import AppSidebar from '@/components/layout/AppSidebar.vue'
import NotFound from '@/components/common/NotFound.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import ServiceWorker from './ServiceWorker.vue'
import InitAuthMixin from './InitAuthMixin.vue'

@Options({
  components: {
    AppSidebar,
    AppFooter,
    NotFound,
  },
})
export default class App extends mixins(ServiceWorker, InitAuthMixin) {
  get isHiddenLeftSidbar() {
    return this.$route.meta.isHiddenLeftSidbar
  }

  get alreadyGetProfileOnInit() {
    return this.$store.getters.alreadyGetProfileOnInit
  }

  get isNotFound() {
    return this.$store.state.isNotFound
  }

  get isCredentialRoute() {
    return (
      this.$route.name === 'popupdemo' ||
      this.$route.name === 'login' ||
      this.$route.name === 'register' ||
      this.$route.name === 'reset_password' ||
      this.$route.name === 'forgot_password'
    )
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get isRestrictedPage() {
    return this.$route.meta.restricted
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get guestToken() {
    return this.$route.params.guestToken
  }

  get isIframe() {
    return this.$route.meta.isIframe
  }

  redirectLogin() {
    this.goto('login')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  @Watch('$route.params')
  @Watch('alreadyGetProfileOnInit')
  async onRouterChange() {
    const currentUrl = window.location.hostname
    const siteProd = 'line.poti-poti.io'
    if (process.env.VUE_APP_ENV === 'production' && currentUrl !== siteProd) {
      window.location.href = 'https://' + siteProd
    }

    if (this.$route.name !== 'popupdemo') {
      if (!this.isCredentialRoute) {
        if (this.alreadyGetProfileOnInit && !this.isAuth && this.isRestrictedPage) {
          this.redirectLogin()
        }
      } else if (this.isAuth) {
        this.$router.replace({
          name: 'home',
        })
      }

      if (this.$route.name === 'notFound') {
        this.$store.dispatch(SET_PAGE_NOT_FOUND)
      } else {
        this.$store.dispatch(RESET_PAGE_NOT_FOUND)
      }
    }
  }

  async created() {
    if (this.$route.name !== 'popupdemo') {
      await this.fetchMe()
    }
  }
}
