import { IRichMenu, IRichMenuRequest } from '@/utils/types'
import { NormApi } from './norm'

export class RichMenuApi extends NormApi<IRichMenu> {
  list = async (params: IRichMenuRequest) => {
    const response = await this.axios.get('/v1/rich-menu/list', {
      params,
    })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/rich-menu/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IRichMenu) => {
    const response = await this.axios.post('/v1/rich-menu/add', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IRichMenu) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/rich-menu/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/rich-menu/${id}`)
    return this.responseHandler(response)
  }

  active = async (payload: IRichMenu) => {
    const response = await this.axios.post(`/v1/rich-menu/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: IRichMenu) => {
    const response = await this.axios.post(`/v1/rich-menu/${payload._id}/mode/test`, payload)
    return this.responseHandler(response)
  }
}

const richMenuApi = new RichMenuApi()
export default richMenuApi
