import { ACTION_PUSH } from './actions'
import { IOverViewRequest, IPushCampaign } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import pushApi from '@/api/push'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IPushCampaign[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    pushs: (state: TState) => {
      return state.items as IPushCampaign[]
    },
  },
  mutations: {
    // [List]
    [ACTION_PUSH.SET_ITEMS]: (state: TState, { items }: { items: IPushCampaign[] }) => {
      state.items = cloneDeep(items) as IPushCampaign[]
    },

    // [New item]
    [ACTION_PUSH.SET_NEW_ITEM]: (state: TState, { item }: { item: IPushCampaign }) => {
      state.items = apiListChangedHandler<IPushCampaign>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_PUSH.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IPushCampaign }) => {
      state.items = apiListChangedHandler<IPushCampaign>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_PUSH.SET_DELETED_ITEM]: (state: TState, { item }: { item: IPushCampaign }) => {
      state.items = apiListChangedHandler<IPushCampaign>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_PUSH.LOAD_ITEMS_SUMMARY]: async ({ commit }, params: IPushCampaign) => {
      const key = constant.LOCAL_KEYS.PUSHES + params.app_id + '-summary-' + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await pushApi.listSummary(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_PUSH.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_PUSH.LOAD_ITEMS_WITH_DETAIL]: async ({ commit }, params: IPushCampaign) => {
      const key = constant.LOCAL_KEYS.PUSHES + params.app_id + '-with-detail-' + +JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await pushApi.getAllItemsWithDetail(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_PUSH.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_PUSH.LOAD_ITEMS_WITHOUT_DETAIL]: async ({ commit }, params: IPushCampaign) => {
      const key = constant.LOCAL_KEYS.PUSHES + params.app_id + '-without-detail-' + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await pushApi.getAllItemsWithoutDetail(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_PUSH.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_PUSH.LOAD_ITEM]: async ({ commit }, id: string) => {
      return await pushApi.single(id)
    },
    // eslint-disable-next-line
    [ACTION_PUSH.LOAD_STORY_ITEM]: async ({ commit }, id: string) => {
      const resp = await pushApi.story_single(id)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_PUSH.LOAD_STORY_ITEMS]: async ({ commit }, id: string) => {
      const resp = await pushApi.story_list(id)
      return resp
    },
    [ACTION_PUSH.ADD]: async ({ commit }, payload: IPushCampaign) => {
      const resp = await pushApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.DELIVER_LOGS + resp.app_id)
        commit(ACTION_PUSH.SET_NEW_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_PUSH.UPDATE]: async ({ commit }, payload: IPushCampaign) => {
      const resp = await pushApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.DELIVER_LOGS + resp.app_id)
        commit(ACTION_PUSH.SET_UPDATED_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_PUSH.DELETE]: async ({ commit }, id: string) => {
      const resp = await pushApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.DELIVER_LOGS + resp.app_id)
        commit(ACTION_PUSH.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_PUSH.IS_ACTIVE]: async ({ commit }, payload: IPushCampaign) => {
      const resp = await pushApi.active(payload)
      if (resp?._id) {
        console.log(resp, 'resp')

        localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.DELIVER_LOGS + resp.app_id)
        commit(ACTION_PUSH.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    [ACTION_PUSH.TEST_MODE]: async ({ commit }, payload: IPushCampaign) => {
      const resp = await pushApi.testMode(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.DELIVER_LOGS + resp.app_id)
        commit(ACTION_PUSH.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    // eslint-disable-next-line
    [ACTION_PUSH.RUN_NOW]: async ({ commit }, id: string) => {
      const resp = await pushApi.runNow(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.PUSHES + resp.app_id)
        localStore.removeStartsWith(constant.LOCAL_KEYS.DELIVER_LOGS + resp.app_id)
        return true
      }
      return false
    },
    // eslint-disable-next-line
    [ACTION_PUSH.OVERVIEW_DETAIL]: async ({ commit }, payload: IOverViewRequest) => {
      const resp = await pushApi.pushOverviewDetail(payload)
      return resp
    },

    // eslint-disable-next-line
    [ACTION_PUSH.ERRORS_QUOTA]: async ({ commit }, payload: IOverViewRequest) => {
      const resp = await pushApi.errorQuota(payload)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_PUSH.WARNING_DELAY]: async ({ commit }, payload: IPushCampaign) => {
      const resp = await pushApi.warningDelay(payload)
      return resp
    },
  },
}
