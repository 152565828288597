// import { createI18n } from 'vue-i18n'
import { createI18n } from 'vue-i18n/index'
import { localStore } from '@/utils/localstore'
import ja from '@/i18n/ja.json'
import en from '@/i18n/en.json'

const i18n = createI18n({
  locale: localStore.getItem('lang') || navigator.language.toLowerCase(),
  fallbackLocale: 'en',
  messages: {
    ja,
    en,
    'en-us': en,
  },
  warnHtmlInMessage: 'off',
})

export default i18n
