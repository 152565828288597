import { IInitialCampaign, IInitialRequest, IOverViewRequest } from '@/utils/types'
import { NormApi } from './norm'

export class InitialApi extends NormApi<IInitialCampaign> {
  listSummary = async (payload: IInitialRequest) => {
    const response = await this.axios.post('/v1/initial/list-summary', payload)
    return this.responseHandler(response)
  }

  getAllItemsWithoutDetail = async (params: IInitialRequest) => {
    const response = await this.axios.get('/v1/initial/list/without-detail', { params })
    return this.responseHandler(response)
  }

  getAllItemsWithDetail = async (params: IInitialRequest) => {
    const response = await this.axios.get('/v1/initial/list/with-detail', { params })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/initial/${id}`)
    return this.responseHandler(response)
  }

  story_single = async (id: string) => {
    const response = await this.axios.get(`/v1/initial/story/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IInitialCampaign) => {
    const response = await this.axios.post('/v1/initial', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IInitialCampaign) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/initial/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/initial/${id}`)
    return this.responseHandler(response)
  }

  active = async (payload: IInitialCampaign) => {
    const response = await this.axios.post(`/v1/initial/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: IInitialCampaign) => {
    const response = await this.axios.post(`/v1/initial/${payload._id}/mode/test`, payload)
    return this.responseHandler(response)
  }

  initialOverviewDetail = async (payload: IOverViewRequest) => {
    const response = await this.axios.post('/v1/initial/overview', payload)
    return this.responseHandler(response)
  }
}

const initialApi = new InitialApi()
export default initialApi
