<template>
  <div class="text-center">{{ $t('copyright') }}</div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class AppFooter extends Vue {}
</script>
