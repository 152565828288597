import { VERSION_HISTORY } from './actions'
import { IVersionHistory } from '@/utils/types'
import versionHistoryApi from '@/api/versionHistory'
import cloneDeep from 'lodash/cloneDeep'
import { apiListChangedHandler } from '@/utils/helpers'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IVersionHistory[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    version_histories: (state: TState) => {
      return state.items as IVersionHistory[]
    },
  },
  mutations: {
    // [single]
    [VERSION_HISTORY.SET_ITEMS]: (state: TState, { items }: { items: IVersionHistory[] }) => {
      state.items = cloneDeep(items) as IVersionHistory[]
    },

    // [UPDATE]]
    [VERSION_HISTORY.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IVersionHistory }) => {
      state.items = apiListChangedHandler<IVersionHistory>(state.items, cloneDeep(item), 'modified')
    },
  },
  actions: {
    [VERSION_HISTORY.LOAD_ITEMS]: async ({ commit }, params: IVersionHistory) => {
      const key = constant.LOCAL_KEYS.VERSION_HISTORY + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await versionHistoryApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(VERSION_HISTORY.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [VERSION_HISTORY.LOAD_ITEM_LATEST]: async ({ commit }, params: IVersionHistory) => {
      const resp = await versionHistoryApi.latest(params)
      return resp
    },
    [VERSION_HISTORY.UPDATE]: async ({ commit }, payload: IVersionHistory) => {
      const resp = await versionHistoryApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.VERSION_HISTORY + resp.app_id)
        commit(VERSION_HISTORY.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
      return null
    },
  },
}
