import { NormApi } from '@/api/norm'
import { IPermission, IPermissionUsers, IPermissionRequest } from '@/utils/types'

export class PermissionApi extends NormApi<IPermission> {
  list_users = async (params: IPermissionRequest) => {
    const response = await this.axios.get('/v1/permission-users', {
      params,
    })
    return this.responseHandler(response)
  }

  sync_users = async (payload: IPermissionUsers) => {
    const response = await this.axios.post('/v1/sync-permission-users', payload)
    return this.responseHandler(response)
  }
}

const Permission = new PermissionApi()
export default Permission
