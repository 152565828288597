import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import findIndex from 'lodash/findIndex'

export const randomString = (length?: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  const n = length || 15
  for (let i = 0; i < n; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const formatDate = (date: string) => {
  if (date) {
    return dayjs(date).format('YYYY/MM/DD')
  }

  return ''
}

export const formatDateTime = (date: string) => {
  if (date) {
    return dayjs(date).format('YYYY/MM/DD HH:mm')
  }

  return ''
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const apiListChangedHandler = <T>(
  currentList: T[],
  newData: T,
  changeType?: 'added' | 'modified' | 'removed'
) => {
  const list = cloneDeep(currentList)
  if (changeType === 'added') {
    list.push(newData)
    return uniqBy(list, '_id')
  }

  // eslint-disable-next-line
  const index = list.findIndex((item: any) => item._id === (newData as any)._id)
  if (changeType === 'removed') {
    list.splice(index, 1)
  } else if (changeType === 'modified') {
    list[index] = newData
  }

  return uniqBy(list, '_id')
}

export const onGotoLineApp = () => {
  window.open('https://developers.line.biz/console/')
}

export const sortListByOrdering = <T>(list: T[]) => {
  // eslint-disable-next-line
  return list.sort((a, b) => ((a as any).ordering || 0) - ((b as any).ordering || 0))
}

export const calcOrderingDragend = <T>(item: T, finalList: T[]) => {
  // eslint-disable-next-line
  const record = item as any
  // eslint-disable-next-line
  const list = finalList as any[]

  // eslint-disable-next-line
  const dragToIndex = findIndex(list, (item: any) => item._id === record._id)
  const prevPosition = list[dragToIndex - 1]?.ordering || 0
  const nextPosition = list[dragToIndex + 1]?.ordering || dayjs().unix()
  const diff = Math.round((nextPosition - prevPosition) / 2)
  const finalPosition = prevPosition + Math.abs(diff)

  return finalPosition
}
