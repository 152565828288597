import {
  IActivityLog,
  ICommonRequest,
} from '@/utils/types'
import { NormApi } from './norm'

export class ActivityLogApi extends NormApi<IActivityLog> {
  list = async (payload: ICommonRequest) => {
    const response = await this.axios.post('/v1/activity_log/list', payload)
    return this.responseHandler(response)
  }

  contentArraybuffer = async (payload: ICommonRequest) => {
    const response = await this.axios.post('/v1/activity_log/content', payload, { responseType: 'arraybuffer' })
    return this.responseHandler(response)
  }

  contentBlod = async (payload: ICommonRequest) => {
    const response = await this.axios.post('/v1/activity_log/content', payload, { responseType: 'blob' })
    return this.responseHandler(response)
  }
}

const activityLogApi = new ActivityLogApi()
export default activityLogApi
