
import { IUser } from '@/utils/types'
import { Vue, Options } from 'vue-class-component'

import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class UserAvatar extends Vue {
  @Prop({ default: '' })
  user!: IUser

  @Prop({ default: 40 })
  size!: number

  get user2CharFirst() {
    return this.user?.display_name?.charAt(0)?.toUpperCase() || 'U'
  }
}
