import { createApp } from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
// Highlight.js languages (Only required languages)
import VueHighlightJS from 'vue3-highlightjs'
import 'highlight.js/styles/solarized-light.css'
import quasarUserOptions from './quasar-user-options'
import panZoom from 'vue-panzoom'

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueHighlightJS)
  .use(panZoom)
  .mount('#app')
