import { ACTION_INITIAL } from './actions'
import { IInitialCampaign, IInitialRequest, IOverViewRequest } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import initialApi from '@/api/initial'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IInitialCampaign[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    initials: (state: TState) => {
      return state.items as IInitialCampaign[]
    },
  },
  mutations: {
    // [List]
    [ACTION_INITIAL.SET_ITEMS]: (state: TState, { items }: { items: IInitialCampaign[] }) => {
      state.items = cloneDeep(items)
    },

    // [New item]
    [ACTION_INITIAL.SET_NEW_ITEM]: (state: TState, { item }: { item: IInitialCampaign }) => {
      state.items = apiListChangedHandler<IInitialCampaign>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_INITIAL.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IInitialCampaign }) => {
      state.items = apiListChangedHandler<IInitialCampaign>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_INITIAL.SET_DELETED_ITEM]: (state: TState, { item }: { item: IInitialCampaign }) => {
      state.items = apiListChangedHandler<IInitialCampaign>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_INITIAL.LOAD_ITEMS_SUMMARY]: async ({ commit }, params: IInitialRequest) => {
      const key = constant.LOCAL_KEYS.INITIALS + params.app_id + '-summary-' + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await initialApi.listSummary(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_INITIAL.SET_ITEMS, { items: resp })
      return resp
    },

    // eslint-disable-next-line
    [ACTION_INITIAL.LOAD_ITEMS_WITH_DETAIL]: async ({ commit }, params: IInitialRequest) => {
      const key = constant.LOCAL_KEYS.INITIALS + params.app_id + '-with-detail-' + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await initialApi.getAllItemsWithDetail(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_INITIAL.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_INITIAL.LOAD_ITEMS_WITHOUT_DETAIL]: async ({ commit }, params: IInitialRequest) => {
      const key = constant.LOCAL_KEYS.INITIALS + params.app_id + '-without-detail-' + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await initialApi.getAllItemsWithoutDetail(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_INITIAL.SET_ITEMS, { items: resp })
      return resp
    },

    // eslint-disable-next-line
    [ACTION_INITIAL.LOAD_ITEM]: async ({ commit }, id: string) => {
      return await initialApi.single(id)
    },
    // eslint-disable-next-line
    [ACTION_INITIAL.LOAD_STORY_ITEM]: async ({ commit }, id: string) => {
      const resp = await initialApi.story_single(id)
      return resp
    },
    [ACTION_INITIAL.ADD]: async ({ commit }, payload: IInitialCampaign) => {
      const resp = await initialApi.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + resp.app_id)
        commit(ACTION_INITIAL.SET_NEW_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_INITIAL.UPDATE]: async ({ commit }, payload: IInitialCampaign) => {
      const resp = await initialApi.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + resp.app_id)
        commit(ACTION_INITIAL.SET_UPDATED_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_INITIAL.DELETE]: async ({ commit }, id: string) => {
      const resp = await initialApi.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + resp.app_id)
        commit(ACTION_INITIAL.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_INITIAL.IS_ACTIVE]: async ({ commit }, payload: IInitialCampaign) => {
      const resp = await initialApi.active(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + payload.app_id)
        commit(ACTION_INITIAL.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    [ACTION_INITIAL.TEST_MODE]: async ({ commit }, payload: IInitialCampaign) => {
      const resp = await initialApi.testMode(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.INITIALS + payload.app_id)
        commit(ACTION_INITIAL.SET_UPDATED_ITEM, { item: payload })
        return true
      }

      return false
    },
    // eslint-disable-next-line
    [ACTION_INITIAL.OVERVIEW_DETAIL]: async ({ commit }, payload: IOverViewRequest) => {
      const resp = await initialApi.initialOverviewDetail(payload)
      return resp
    },
  },
}
