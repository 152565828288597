<template>
  <q-banner v-if="showMessage" dense inline-actions class="text-white bg-negative">
    {{ push_names }} {{ $t('messages.campaigns_failed') }}
    <template v-slot:action>
      <q-btn flat :label="$t('dismiss')" @click="dismissMessage()" no-caps />
    </template>
  </q-banner>
</template>
<script lang="ts">
import { Vue } from 'vue-class-component'
import { ACTION_PUSH } from '@/store/actions'
import { Watch } from 'vue-property-decorator'
import { IPushCampaign } from '@/utils/types'
export default class CampaignsFailedMessage extends Vue {
  showMessage = false
  pushs: IPushCampaign[] = []
  push_ids: (string | undefined)[] = []
  push_names = ''

  get appId() {
    return this.$route.params.app_id
  }

  async fetchSelectingApp() {
    if (!this.appId) {
      this.showMessage = false
      return
    }

    this.push_ids = JSON.parse(localStorage.getItem('campaigns-failed-' + this.appId) || '[]')

    this.pushs = await this.$store.dispatch(ACTION_PUSH.ERRORS_QUOTA, {
      app_id: this.appId,
    })
    const ids = this.pushs.map((item) => item._id)
    const names = this.pushs.map((item) => item.title)
    this.push_names = names.join(', ')
    if (!(ids.length === this.push_ids.length && ids.every((value, index) => value === this.push_ids[index]))) {
      this.showMessage = true
      this.push_ids = ids
    }
  }

  dismissMessage() {
    localStorage.setItem('campaigns-failed-' + this.appId, JSON.stringify(this.push_ids))
    this.showMessage = false
  }

  @Watch('appId', { immediate: true })
  appIdChanged() {
    this.fetchSelectingApp()
  }
}
</script>
