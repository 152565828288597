import { ACTION_APP_USER, ACTION_ANSWER_REPORT } from './actions'
import { IAppUser, IFormReportRequest } from '@/utils/types'
import appUserApi from '@/api/appUser'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IAppUser[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    appUsers: (state: TState) => {
      return state.items as IAppUser[]
    },
  },
  mutations: {
    // [List]
    [ACTION_APP_USER.SET_ITEMS]: (state: TState, { items }: { items: IAppUser[] }) => {
      state.items = cloneDeep(items)
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_APP_USER.LOAD_ITEMS]: async ({ commit }, params: IAppUser) => {
      // const key = constant.LOCAL_KEYS.APP_USERS + params.app_id + JSON.stringify(params)
      // let resp
      // resp = localStore.getWithExpiry(key)
      // if (!resp) {
      //   resp = await appUserApi.list(params)
      //   localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      // }
      const resp = await appUserApi.list(params)
      commit(ACTION_APP_USER.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_APP_USER.LOAD_ITEM]: async ({ commit }, id: string) => {
      const resp = await appUserApi.single(id)
      return resp
    },
    // eslint-disable-next-line
    [ACTION_ANSWER_REPORT.LOAD_USER_DETAILS]: async ({ commit }, params: IFormReportRequest) => {
      const key = constant.LOCAL_KEYS.ANSWER_REPORT + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await appUserApi.formReports(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      return resp
    },
  },
}
