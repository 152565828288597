import { IMedia } from '@/utils/types'
import { NormApi } from './norm'

export class MediaApi extends NormApi<IMedia> {
  list = async (payload: IMedia) => {
    const response = await this.axios.post('/v1/media/list', payload)
    return this.responseHandler(response)
  }

  add = async (payload: IMedia) => {
    const response = await this.axios.post('/v1/media', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IMedia) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/media/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/media/${id}`)
    return this.responseHandler(response)
  }
}
const mediaApi = new MediaApi()
export default mediaApi
