
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class AppLogo extends Vue {
  @Prop()
  miniState!: boolean

  gotoHome() {
    this.$router.push({ name: 'home' })
  }
}
