import { USER_ANSWER } from './actions'
import { IAudience, IAudienceAnswerRequest } from '@/utils/types'
import userAnswerApi from '@/api/userAnswer'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

// type UserAnaswerState = {
//   user_answers: IUserAnswer[]
//   loading: boolean
// }

export default {
  state: {
    // user_answers: [],
    loading: false,
  },
  getters: {
    // userAnaswers: (state: UserAnaswerState) => {
    //   return state.user_answers as IUserAnswer[]
    // },
  },
  mutations: {},
  actions: {
    // [USER_ANSWER.LOAD_ITEMS]: async ({ commit }, payload: IAudienceAnswerRequest) => {
    //   const key = constant.LOCAL_KEYS.USER_ANSWER + payload.app_id + JSON.stringify(payload)
    //   let resp
    //   resp = localStore.getWithExpiry(key)
    //   if (!resp) {
    //     resp = await userAnswerApi.list(payload)
    //     localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
    //   }

    //   // const resp = await userAnswerApi.list(payload)
    //   commit(USER_ANSWER.SET_ITEMS, { user_answers: resp })
    //   return resp
    // },
    // eslint-disable-next-line
    [USER_ANSWER.USER_ANSWER_ROWS]: async ({ commit }, payload: IAudience) => {
      const resp = await userAnswerApi.userAnswerRows(payload)
      return resp
    },

    // eslint-disable-next-line
    [USER_ANSWER.TOTAL_USERS_REPORT_SCENARIO]: async ({ commit }, payload: IAudienceAnswerRequest) => {
      const key = constant.LOCAL_KEYS.TOTAL_USERS_REPORT_SCENARIO + payload.app_id + JSON.stringify(payload)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await userAnswerApi.total_user_answers_report_scenario(payload)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      return resp
    },

    // eslint-disable-next-line
    [USER_ANSWER.GET_USER_ANSWER_BY_CAMPAIGN]: async ({ commit }, payload: IAudienceAnswerRequest) => {
      const resp = await userAnswerApi.get_user_answers_by_campaign_id(payload)
      return resp
    },
  },
}
