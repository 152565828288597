import { NormApi } from '@/api/norm'
import { IAutoResponse, IAutoResponseRequest } from '@/utils/types'

export class AutoResponseApi extends NormApi<IAutoResponse> {
  list = async (params: IAutoResponseRequest) => {
    const response = await this.axios.post('/v1/auto-response/list', params)
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/auto-response/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IAutoResponse) => {
    const response = await this.axios.post('/v1/auto-response', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IAutoResponse) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/auto-response/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/auto-response/${id}`)
    return this.responseHandler(response)
  }

  active = async (payload: IAutoResponse) => {
    const response = await this.axios.post(`/v1/auto-response/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }
}

const AutoResponse = new AutoResponseApi()
export default AutoResponse
