
import { Vue, Options } from 'vue-class-component'
import { ACTION_PROFILE } from './store/actions'
import { localStore } from './utils/localstore'
import { APP_USER_STORE_KEY } from './utils/constants'

@Options({
  components: {},
})
export default class InitAuthMixin extends Vue {
  async fetchMe() {
    const { commit, dispatch } = this.$store
    const token = localStore.getItem(APP_USER_STORE_KEY.AUTH_TOKEN)
    if (!token) {
      commit(ACTION_PROFILE.SAVE, { user: null })
      return
    }

    await dispatch(ACTION_PROFILE.LOAD)
  }
}
