
import { Vue, Options } from 'vue-class-component'
import { UserModel } from '@/models/user-model'

import AppSidebarLeft from '@/components/layout/AppSidebarLeft.vue'
import CampaignsFailedMessage from '@/components/app/CampaignsFailedMessage.vue'

@Options({
  components: {
    CampaignsFailedMessage,
    AppSidebarLeft,
  },
  emits: ['update:collapsed'],
})
export default class AppSidebar extends Vue {
  get userInfo(): UserModel {
    return this.$store.getters.userInfo
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get activeRouteKey() {
    return this.$route.meta?.menuKey
  }

  gotoUserProfile() {
    this.goto('user_profile_view', { id: this.userInfo._id })
  }

  gotoEditProfile() {
    this.goto('user_profile_edit')
  }

  gotoHome() {
    this.goto('home')
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
