import { ITargetSetting, ITargetSettingRequest } from '@/utils/types'
import { NormApi } from './norm'

export class TargetSettingApi extends NormApi<ITargetSetting> {
  list = async (params: ITargetSettingRequest) => {
    const response = await this.axios.get('/v1/target-setting/list', {
      params,
    })
    return this.responseHandler(response)
  }

  getAllItemsWithoutDetail = async (params: ITargetSettingRequest) => {
    const response = await this.axios.get('/v1/target-setting/list/without-detail', { params })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/target-setting/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: ITargetSetting) => {
    const response = await this.axios.post('/v1/target-setting/add', payload)
    return this.responseHandler(response)
  }

  update = async (payload: ITargetSetting) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/target-setting/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/target-setting/${id}`)
    return this.responseHandler(response)
  }

  active = async (payload: ITargetSetting) => {
    const response = await this.axios.post(`/v1/target-setting/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: ITargetSetting) => {
    const response = await this.axios.post(`/v1/target-setting/${payload._id}/mode/test`, payload)
    return this.responseHandler(response)
  }
}

const targetSettingApi = new TargetSettingApi()
export default targetSettingApi
