import { render } from "./AppLogo.vue?vue&type=template&id=541a5967&scoped=true"
import script from "./AppLogo.vue?vue&type=script&lang=ts"
export * from "./AppLogo.vue?vue&type=script&lang=ts"

import "./AppLogo.vue?vue&type=style&index=0&id=541a5967&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-541a5967"

export default script
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QAvatar});qInstall(script, 'directives', {Ripple});
