import { render } from "./AppSidebarTop.vue?vue&type=template&id=3f62c9d6&scoped=true"
import script from "./AppSidebarTop.vue?vue&type=script&lang=ts"
export * from "./AppSidebarTop.vue?vue&type=script&lang=ts"

import "./AppSidebarTop.vue?vue&type=style&index=0&id=3f62c9d6&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-3f62c9d6"

export default script
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QBtn,QToolbarTitle,QMenu,QList,QItem,QItemSection,QIcon,QSeparator});qInstall(script, 'directives', {ClosePopup});
