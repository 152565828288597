export default class BaseModel {
  // eslint-disable-next-line
  constructor(obj: any) {
    for (const key of Object.keys(obj)) {
      this[key] = obj[key]
    }
  }

  sertialize() {
    throw new Error('Serialize need to implement on child class')
  }
}
