import { IApp, IOverViewRequest, IValidRequest } from '@/utils/types'
import { NormApi } from './norm'

export class AppApi extends NormApi<IApp> {
  list = async () => {
    const response = await this.axios.get('/v1/apps')
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/apps/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IApp) => {
    const response = await this.axios.post('/v1/apps', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IApp) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/apps/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/apps/${id}`)
    return this.responseHandler(response)
  }

  exportChannels = async () => {
    const response = await this.axios.get('/v1/apps/export/channels')
    return this.responseHandler(response)
  }

  checkLimitCards = async (params: IValidRequest) => {
    const response = await this.axios.post('/v1/apps/scenario/validate-limit-card', params)
    return this.responseHandler(response)
  }

  overview = async (payload: IOverViewRequest) => {
    const response = await this.axios.post('/v1/apps/overview', payload)
    return this.responseHandler(response)
  }

  overviewDetail = async (payload: IOverViewRequest) => {
    const response = await this.axios.post('/v1/apps/overview/detail', payload)
    return this.responseHandler(response)
  }

  checkItemExists = async (params: IValidRequest) => {
    const response = await this.axios.post('/v1/apps/scenario/validate-item-exists', params)
    return this.responseHandler(response)
  }
}

const app = new AppApi()
export default app
