<template>
  <q-avatar v-if="user?.photo_url" size="sm">
    <img :src="user?.photo_url" />
  </q-avatar>
  <q-avatar v-else size="sm" color="grey" text-color="white">
    {{ user2CharFirst }}
  </q-avatar>
</template>

<script lang="ts">
import { IUser } from '@/utils/types'
import { Vue, Options } from 'vue-class-component'

import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class UserAvatar extends Vue {
  @Prop({ default: '' })
  user!: IUser

  @Prop({ default: 40 })
  size!: number

  get user2CharFirst() {
    return this.user?.display_name?.charAt(0)?.toUpperCase() || 'U'
  }
}
</script>
