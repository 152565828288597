import { IApp, IOverViewRequest, IValidRequest } from '@/utils/types'
import { ACTION_APP } from './actions'
import { apiListChangedHandler } from '@/utils/helpers'

import cloneDeep from 'lodash/cloneDeep'
import appApi from '@/api/app'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IApp[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    apps: (state: TState) => {
      return state.items as IApp[]
    },
  },
  mutations: {
    // [List]
    [ACTION_APP.SET_ITEMS]: (state: TState, { items }: { items: IApp[] }) => {
      state.items = cloneDeep(items)
    },
    // [New item]
    [ACTION_APP.SET_NEW_ITEM]: (state: TState, { item }: { item: IApp }) => {
      state.items = apiListChangedHandler<IApp>(state.items, cloneDeep(item), 'added')
    },
    // [Update item]
    [ACTION_APP.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IApp }) => {
      state.items = apiListChangedHandler<IApp>(state.items, cloneDeep(item), 'modified')
    },
    // [Deleted item]
    [ACTION_APP.SET_DELETED_ITEM]: (state: TState, { item }: { item: IApp }) => {
      state.items = apiListChangedHandler<IApp>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    [ACTION_APP.LOAD_ITEMS]: async ({ commit }) => {
      const key = constant.LOCAL_KEYS.APPS
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await appApi.list()
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      commit(ACTION_APP.SET_ITEMS, { items: resp })
    },
    // eslint-disable-next-line
    [ACTION_APP.LOAD_ITEM]: async ({ commit }, id: string) => {
      return await appApi.single(id)
    },
    [ACTION_APP.ADD_NEW]: async ({ commit }, payload: IApp) => {
      const resp = await appApi.add(payload)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.APPS)
        commit(ACTION_APP.SET_NEW_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_APP.UPDATE]: async ({ commit }, payload: IApp) => {
      const resp = await appApi.update(payload)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.APPS)
        commit(ACTION_APP.SET_UPDATED_ITEM, { item: resp })
        return true
      }
    },
    [ACTION_APP.DELETE]: async ({ commit }, id: string) => {
      const resp = await appApi.delete(id)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.APPS)
        commit(ACTION_APP.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
    // eslint-disable-next-line
    [ACTION_APP.EXPORT_CHANNELS]: async ({ commit }) => {
      return await appApi.exportChannels()
    },

    // eslint-disable-next-line
    [ACTION_APP.CHECK_LIMIT_CARDS]: async ({ commit }, params: IValidRequest) => {
      const resp = await appApi.checkLimitCards(params)
      return resp
    },

    // eslint-disable-next-line
    [ACTION_APP.OVERVIEW]: async ({ commit }, payload: IOverViewRequest) => {
      const key = constant.LOCAL_KEYS.APP_OVER_VIEW + payload.app_id + JSON.stringify(payload)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await appApi.overview(payload)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      return resp
    },
    // eslint-disable-next-line
    [ACTION_APP.OVERVIEW_DETAIL]: async ({ commit }, payload: IOverViewRequest) => {
      const key = constant.LOCAL_KEYS.APP_OVER_VIEW_DETAIL + payload.app_id + JSON.stringify(payload)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await appApi.overviewDetail(payload)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }

      return resp
    },
    // eslint-disable-next-line
    [ACTION_APP.CHECK_ITEM_EXISTS]: async ({ commit }, params: IValidRequest) => {
      const resp = await appApi.checkItemExists(params)
      return resp
    },
  },
}
