import {
  IAudience,
  IAppUser,
  IAudienceAnswerRequest,
  IAudienceUserDetailRequest,
  IForkAudienceRequest,
} from '@/utils/types'
import { NormApi } from './norm'

export class AudienceApi extends NormApi<IAudience> {
  list = async (payload: IAudience) => {
    const response = await this.axios.post('/v1/audience/list', payload)
    return this.responseHandler(response)
  }

  simple_list = async (payload: IAudience) => {
    const response = await this.axios.post('/v1/audience/list/simple', payload)
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/audience/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IAudience) => {
    const response = await this.axios.post('/v1/audience', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IAudience) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/audience/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/audience/${id}`)
    return this.responseHandler(response)
  }

  answer = async (params: IAudienceAnswerRequest) => {
    const response = await this.axios.get('/v1/audience/answer', { params })
    return this.responseHandler(response)
  }

  users = async (params: IAppUser) => {
    const response = await this.axios.get('/v1/audience/user/list', { params })
    return this.responseHandler(response)
  }

  userDetails = async (payload: IAudienceUserDetailRequest) => {
    const response = await this.axios.post('/v1/audience/user/list/detail', payload)
    return this.responseHandler(response)
  }

  active = async (payload: IAudience) => {
    const response = await this.axios.post(`/v1/audience/${payload._id}/active`, payload)
    return this.responseHandler(response)
  }

  testMode = async (payload: IAudience) => {
    const response = await this.axios.post(`/v1/audience/${payload._id}/mode/test`, payload)
    return this.responseHandler(response)
  }

  fork = async (payload: IForkAudienceRequest) => {
    const response = await this.axios.post('/v1/audience/fork', payload)
    return this.responseHandler(response)
  }
}

const audienceApi = new AudienceApi()
export default audienceApi
