import { IForm, IFormListRequest } from '@/utils/types'
import { ACTION_FORM } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { apiListChangedHandler } from '@/utils/helpers'
import formApi from '@/api/form'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IForm[]
  form: IForm
  loading: boolean
}

export default {
  state: {
    items: [],
    form: [],
    loading: false,
  },
  getters: {
    forms: (state: TState) => {
      return state.items as IForm[]
    },
  },
  mutations: {
    // [List]
    [ACTION_FORM.SET_ITEMS]: (state: TState, { items }: { items: IForm[] }) => {
      state.items = cloneDeep(items)
    },
    // [New item]
    [ACTION_FORM.SET_NEW_ITEM]: (state: TState, { item }: { item: IForm }) => {
      state.items = apiListChangedHandler<IForm>(state.items, cloneDeep(item), 'added')
    },
    // [Update item]
    [ACTION_FORM.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IForm }) => {
      state.items = apiListChangedHandler<IForm>(state.items, cloneDeep(item), 'modified')
    },
    // [Deleted item]
    [ACTION_FORM.SET_DELETED_ITEM]: (state: TState, { item }: { item: IForm }) => {
      state.items = apiListChangedHandler<IForm>(state.items, cloneDeep(item), 'removed')
    },

    // [Answer]
    [ACTION_FORM.SET_ITEM]: (state: TState, { item }: { item: IForm }) => {
      state.form = cloneDeep(item)
    },
  },
  actions: {
    [ACTION_FORM.LOAD_ITEMS]: async ({ commit }, params: IFormListRequest) => {
      const key = constant.LOCAL_KEYS.ASSETS_FORMS + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await formApi.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_FORM.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_FORM.LOAD_ITEM]: async ({ commit }, id: string) => {
      return await formApi.single(id)
    },
    [ACTION_FORM.ADD_NEW]: async ({ commit }, payload: IForm) => {
      const resp = await formApi.add(payload)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_FORMS + resp.app_id)
        commit(ACTION_FORM.SET_NEW_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_FORM.UPDATE]: async ({ commit }, payload: IForm) => {
      const resp = await formApi.update(payload)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_FORMS + resp.app_id)
        commit(ACTION_FORM.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
      return null
    },
    [ACTION_FORM.DELETE]: async ({ commit }, id: string) => {
      const resp = await formApi.delete(id)

      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.ASSETS_FORMS + resp.app_id)
        commit(ACTION_FORM.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
  },
}
