import {
  IAppUser, IFormReportRequest,
} from '@/utils/types'
import { NormApi } from './norm'

export class AppUserApi extends NormApi<IAppUser> {
  list = async (payload: IAppUser) => {
    const response = await this.axios.post('/v1/app_user/list', payload)
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/app_user/${id}`)
    return this.responseHandler(response)
  }

  formReports = async (payload: IFormReportRequest) => {
    const response = await this.axios.post('/v1/app_user/list/detail', payload)
    return this.responseHandler(response)
  }
}

const appUserApi = new AppUserApi()
export default appUserApi
