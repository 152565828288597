import { IScenarioTemplate, ICommonRequest } from '@/utils/types'
import { NormApi } from './norm'

export class ScenarioTemplateApi extends NormApi<IScenarioTemplate> {
  list = async (params: ICommonRequest) => {
    const response = await this.axios.get('/v1/scenario-template/list', { params })
    return this.responseHandler(response)
  }

  getAllItemsWithoutDetail = async (params: ICommonRequest) => {
    const response = await this.axios.get('/v1/scenario-template/list/without-detail', { params })
    return this.responseHandler(response)
  }

  single = async (id: string) => {
    const response = await this.axios.get(`/v1/scenario-template/${id}`)
    return this.responseHandler(response)
  }

  add = async (payload: IScenarioTemplate) => {
    const response = await this.axios.post('/v1/scenario-template', payload)
    return this.responseHandler(response)
  }

  update = async (payload: IScenarioTemplate) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/scenario-template/${_id}`, updateData)
    return this.responseHandler(response)
  }

  delete = async (id: string) => {
    const response = await this.axios.delete(`/v1/scenario-template/${id}`)
    return this.responseHandler(response)
  }
}

const api = new ScenarioTemplateApi()
export default api
