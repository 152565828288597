import Notify from 'quasar/src/plugins/Notify.js';
import i18n from '@/plugins/i18n'

const notifyTypeMapping: Record<string, string> = {
  info: 'positive',
  success: 'positive',
  error: 'negative',
}

export default function errorHandler(error: string | { message: string }, type?: string) {
  if (!error) {
    return
  }

  let description: string
  if (typeof error === 'string' || typeof error === 'number') {
    description = error
  } else {
    description = error.message
  }

  Notify.create({
    type: notifyTypeMapping[type || 'error'],
    message: i18n.global.t(`${description}`),
    html: true,
  })
}
