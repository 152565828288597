import { IVersionHistory } from '@/utils/types'
import { NormApi } from './norm'

export class VersionHistoryApi extends NormApi<IVersionHistory> {
  list = async (params: IVersionHistory) => {
    const response = await this.axios.get('/v1/version-history', { params })
    return this.responseHandler(response)
  }

  latest = async (params: IVersionHistory) => {
    const response = await this.axios.get('/v1/version-history/latest', { params })
    return this.responseHandler(response)
  }

  update = async (payload: IVersionHistory) => {
    const { _id, ...updateData } = payload
    const response = await this.axios.put(`/v1/version-history/${_id}`, updateData)
    return this.responseHandler(response)
  }
}
const versionHistoryApi = new VersionHistoryApi()
export default versionHistoryApi
